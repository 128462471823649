<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="star-same-style">
      <!-- 轮播图 -->
      <b-carousel id="carouselExampleControls" :interval="4000" controls indicators background="#ababab">
        <b-carousel-slide v-for="item in banners" v-bind:key="item.banner_path" :img-src="item.banner_path">
        </b-carousel-slide>
        <div class="search-box">
          <div class="search-input-box">
            <div class="dropdown ">
              <button class="btn btn-secondary dropdown-toggle search-select" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-expanded="false">
                {{selectInfo.title}}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" v-for="(item,index) in selectInfoList" :key="index"
                  @click="selectInfo=item">{{item.title}}</a>
              </div>
            </div>
            <div class="search-split-box">
              <img src="@/../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder" @keyup.enter="search" />
      
            <div class="search-split-box margin-left-auto">
              <img src="@/../public/icon/product-distingush@2x.png" class="search-icon" />
            </div>
            <div class="search-split-box">
              <img src="@/../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <div class="search-split-box margin-right-22" @click="search()">
              <img src="@/../public/icon/search-icon@2x.png" class="search-icon" />
            </div>
          </div>
          <div class="key-words-box">
            <span class="key-words-title">{{$t("homeSearchPlaceholder")}}:</span>
            <span class="key-words" v-for="(item,index) in keyWordsList" :key="index"
              @click="keyWords=item;search()">{{item}}</span>
          </div>
        </div>
      </b-carousel>
      <div class="classify-list">
        <ul>
          <li v-for="(item, index) in classifyList" :key="index" @click="toItemLink(index)">
            <img :src="item.banner_path" alt="">
          </li>
        </ul>
      </div>
      <b-container fluid="100%">
        <b-row class="sort-wrap">
          <b-col class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <div class="sort-wrap-inner">
              <div class="sort-title">
                <i class="el-icon-sort"></i>排序
              </div>
              <div class="sort-items">
                <span :class="sort == item.sort ? 'sort-item selected' : 'sort-item'" v-for="(item, index) in sortArr" :key="index" @click="changeSort(item)">{{item.name}}</span>
                <span :class="[sort == 3 || sort == 4 ?'sort-item-price price-selected':'sort-item-price']" @click="changePriceSort">
                  <span class="text-txt">价格</span>
                  <div class="arrow">
                    <span :class="[priceSortMark ? 'arrow-top-state':'arrow-top']"></span>
                    <span :class="[priceSortMark ? 'arrow-bot-state':'arrow-bot']"></span>
                  </div>
                </span>
                <span class="sort-item-model">
                  <el-checkbox v-model="checked" @change="changeModel">模型</el-checkbox>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="product-items-wrap">
          <b-col class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <div class="product-wrap-inner">
              <waterfall :col="colCount" :data="goodsItems">
                <template>
                  <div class="product-item" v-for="(item, index) in goodsItems" :key="index">
                    <div class="pic" @click="toProductDetail(item)">
                      <img :src="item.file_path" alt="">
                    </div>
                    <div class="detail">
                      <div class="name">{{item.goods_name}}</div>
                      <div class="price">¥{{item.sale_price}}</div>
                      <div class="sales-volume">
                        已售<span>{{item.sale_count}}</span>件
                      </div>
                    </div>
                  </div>
                </template>
              </waterfall>
            </div>
          </b-col>
          <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12 changePage">
            <el-pagination
              v-if="totalPage > 0"
              :page-count="totalPage"
              @current-change="changePage"
              background
              layout="prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
            ></el-pagination>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import right from "@/components/Right";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      footerNav,
      right
    },
    data (){
      return {
        selectInfo: {
          title: "搜单品",
          id: 1
        },
        selectInfoList: [
          {
            title: "搜单品",
            id: 1
          }
        ],
        banners: [
          // {
          //   "banner_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7680e61a-2dac-4f15-8d5d-6c363281d110.png",
          //   "url": "#"
          // },
          // {
          //   "banner_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/06870d62-6891-4a5e-8dde-e8eb7f84210e.png",
          //   "url": "#"
          // },
          // {
          //   "banner_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b3beea89-b7f9-4c6c-b60b-f05ed2ece15f.png",
          //   "url": "#"
          // }
        ],
        keyWords: "",
        keyWordsList: [
          "现代客厅",
          "卧室",
          "极简"
        ],
        searchPlaceholder: this.$t('homeSearchInputPlaceholder'),
        classifyList: [],
        checked: false,
        priceSortMark: false,
        sort: "",
        colCount: 5,
        goodsItems: [],
        totalPage: 0,
        currentPage: 1,
        sortArr: [
          {
            name: "综合",
            sort: ""
          },
          {
            sort: "1",
            name: "热门"
          },
          {
            sort: "2",
            name: "新品"
          }
        ],
        model_type: ""
      }
    },
    mounted() {
      
    },
    created() {
      this.keyWords = this.$route.query.keyWords;
      this.getBanner();
      this.getSelectList();
      this.getStarSame();
    },
    methods: {
      changePriceSort(){
        this.priceSortMark = !this.priceSortMark;
        this.sort = this.priceSortMark ? 3 : 4;
        this.getStarSame();
      },
      changePage(page){
        this.currentPage = page
        console.log(page)
        this.getStarSame();
      },
      // 获取明星同款banner
      getBanner(){
        this.$api.getbanners({banner_type: 17}).then(res => {
          this.banners = res.data;
        }).catch(err => console.error(err))
      },
      // 获取明星同款筛选列表
      getSelectList(){
        this.$api.getbanners({banner_type: 18}).then(res => {
          this.classifyList = res.data;
        }).catch(err => console.error(err))
      },
      // 明星同款
      getStarSame(){
        const params = {
          currentPage: this.currentPage,
          page_size: 30,
          sort_type: this.sort,
          keywords: this.keyWords,
          // city_id: that.cityId,
          star_type: 1,
          model_type: this.model_type
        }
        this.$api.getGoods(params).then(res => {
          this.goodsItems = res.data.goods;
          this.totalPage = res.data.totalPage;
        }).catch(err => console.error(err))
      },
      toProductDetail(obj){
        dump.link({
          type: 1,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      },
      // 切换排序
      changeSort(obj){
        this.sort = obj.sort;
        this.getStarSame();
      },
      search(){
        this.getStarSame()
      },
      toItemLink(i){
        console.log(i)
        switch(i){
          case 0: this.keyWords = "客厅";
          break;
          case 1: this.keyWords = "餐厅";
          break;
          case 2: this.keyWords = "卧室";
          break;
          case 3: this.keyWords = "书房";
          break;
        }
        this.getStarSame()
      },
      changeModel(val){
        this.model_type = val ? 1 : "";
        this.getStarSame();
      }
    }
    
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "starSameStyle";
</style>