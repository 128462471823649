import { render, staticRenderFns } from "./starSameStyle.vue?vue&type=template&id=bebb50d4&scoped=true&"
import script from "./starSameStyle.vue?vue&type=script&lang=js&"
export * from "./starSameStyle.vue?vue&type=script&lang=js&"
import style0 from "./starSameStyle.vue?vue&type=style&index=0&id=bebb50d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bebb50d4",
  null
  
)

export default component.exports